<template>
  <div>
    <div id="main" ref="chart"></div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  props: ["dateList", "valueList", "hValue", "lValue", "unit", "chartTitle"],
  name: "charts",
  data() {
    return {
      data: {
        title: {
          text: "历史值显示",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }

          },
        },

        legend: {},

        grid: {
          top: 70,
          bottom: 50,
        },

        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return (
                  params.value
                );
              },
            },
          },
          data: this.dateList.reverse(),
        },

        axisLine: {
          onZero: false,
          lineStyle: {
            color: ["#008c8c"],
          },
        },
        axisPointer: {
          label: {
            formatter: function (params) {
              return (
                Number(params.value).toFixed(5) +
                (params.seriesData.length
                  ? "：" + params.seriesData[0].data
                  : "")
              );
            },
          },
        },
        dataZoom: { // 放大和缩放
          type: 'inside'
        },
        yAxis: {},
        series: [
          {
            name: "高阈值",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },

            data: [],
          },
          {
            name: "值",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
            // label: {
            //   show: true,
            // },
            data: this.valueList.reverse(),
          },
          {
            name: "低阈值",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
            // label: {
            //   show: true,
            // },
            data: [],
          },
        ],
      },
      hValueList: [], //折线图高阈值数组
      lValueList: [], //折线图低阈值数组
    };
  },
  created() {
  },
  mounted() {
    for (let i in this.dateList) {
      this.hValueList.push(this.hValue);
      this.lValueList.push(this.lValue);
    }
    if (this.hValue != "" && this.hValue != null && this.hValue != undefined) {
      this.data.series[0].data = this.hValueList;
    } else {
      this.data.series[0].name = "";
    }
    if (this.lValue != "" && this.lValue != null && this.lValue != undefined) {
      this.data.series[2].data = this.lValueList;
    } else {
      this.data.series[2].name = "";
    }
    if (this.chartTitle != "" && this.chartTitle != null && this.chartTitle != undefined) {
      this.data.title.text = this.chartTitle
    }
    if (this.unit != "" && this.unit != null && this.unit != undefined) {
      this.data.title.text += "(单位：" + this.unit + ")"
    }
    let myChart = echarts.init(this.$refs.chart)
    myChart.setOption(this.data);
    window.onresize = myChart.resize
  },
};
</script>

<style scoped>
#main {
  width: 100%;
  height: 380px;
  box-sizing: border-box;
}
</style>

