<template>
  <div id="app">
    <!-- 头部 -->
    <div style="display:flex;margin:10px 0">
      <div class="item">
        <el-select v-model="handleStatus" clearable placeholder="请选择处理状态">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <el-select v-model="typeName" filterable clearable placeholder="请选择设备类型">
          <el-option v-for="item in deviceType" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <el-input v-model="deviceId" placeholder="请输入设备号" clearable></el-input>
      </div>
      <div class="item">
        <el-input v-model="value" placeholder="请输入报警类型"></el-input>
      </div>
      <!-- <div   class="item">
        <el-input v-model="companyName" placeholder="请输入公司名称"></el-input>
      </div> -->

      <!-- <div   class="item">
        <el-select v-model="companyName" filterable clearable placeholder="请选择公司名称">
          <el-option v-for="item in companyNameList" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </div> -->


      <div class="item" style="width:500px;">
        <el-cascader placeholder="请选择公司名称" filterable v-model="companyName" style="width:100%" :options="allCompanyList" collapse-tags
          :props="defaultProps" @change="searchDeviceCompanyInfo($event)" ref="cascader1" clearable>
        </el-cascader>
      </div>
      <div class="item">
        <el-date-picker style="width:auto" v-model="dateValue" type="datetimerange" :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <el-button icon="el-icon-search" @click="getAllByHandleStatus" type="primary">
          查询
        </el-button>

      </div>
      <div class="item">
        <el-button type="primary" @click="restList">重置</el-button>
      </div>
      <div class="item">
        <el-button type="success" @click="export2Excel"
          :loading="loading">{{ loading == true ? loadingText : exportText }}</el-button>
      </div>
      <div class="item">
        <el-button type="success" @click="aKeyProcess" v-if="userRightList.updateJuris == '1'">一键处理</el-button>
      </div>
      <div class="item" style="width:15%;">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 表格 -->
    <el-table highlight-current-row :data="deviceHistoryStatuss"
      :header-cell-style="{ textAlign: 'center', background: '#eef1f6' }" :cell-style="{ textAlign: 'center' }"
      :row-class-name="tableRowClassName" :border="true" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column fixed type="index" :index="indexMethod" label="序号" width="50">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('设备号')" prop="deviceId" label="设备号">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('设备类型')" prop="typeName" label="设备类型">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('公司名称')" prop="companyName" label="公司名称">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('安装位置')" prop="note" label="安装位置">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('报警类型')" prop="value" label="报警类型">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('报警时间')" prop="createTime" label="报警时间">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('处理状态')" prop="handleStatus" label="处理状态">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('处理过程')" prop="process" label="处理过程">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('处理结果')" prop="processType" label="处理结果">
      </el-table-column>
      <!-- <el-table-column v-if="tableTitle.includes('处理类型')" prop="processType" label="处理类型">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('处理说明')" prop="process" label="处理说明"> </el-table-column> -->

      <el-table-column fixed="right" label="操作" width="330">
        <template slot-scope="scope">
          <el-button @click="showVideo(scope.row)" :type="scope.row.videoUrl != null ? 'success' : 'warning'"
            size="mini">视频</el-button>
          <el-button @click="handleClick(scope.row)" type="success" size="mini"
            v-if="userRightList.updateJuris == '1'">处理</el-button>
          <el-button type="primary" @click="getOneDeviceHistoryValue(scope.row)" size="mini">查看</el-button>
          <el-button @click="toCRT(scope.row)" type="primary" size="small">CRT</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="详情" :visible.sync="dialogc" width="60%">
      <img :src="baseUrl + actualUrl" style="width: 70%; margin: 0 auto" />
    </el-dialog>

    <!-- 分页组件 -->
    <el-pagination :background="true" @current-change="handleCurrentChange" :current-page.sync="currentPage"
      :page-size="10" layout="total, prev, pager, next , jumper" :total="total">
    </el-pagination>
    <!-- 处理框 -->
    <div>
      <el-dialog title="处理设备状态" :visible.sync="dialogFormVisible" width="64%">
        <el-form :model="deviceHistoryStatus">
          <el-form-item label="设备型号:">
            <el-input class="inPutStyle" size="small" style="width: 180px" v-model="deviceHistoryStatus.deviceId"
              autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="设备类型:">
            <el-input class="inPutStyle" size="small" style="width: 180px" v-model="deviceHistoryStatus.typeName"
              autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="设备属性:">
            <el-input class="inPutStyle" size="small" style="width: 180px" v-model="deviceHistoryStatus.valueName"
              autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="设备状态:">
            <el-input class="inPutStyle" size="small" style="width: 180px" v-model="deviceHistoryStatus.value"
              autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间:">
            <el-input class="inPutStyle" size="small" style="width: 180px" v-model="deviceHistoryStatus.createTime"
              autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="处理状态:">
            <el-input disabled class="inPutStyle" size="small" style="width: 180px"
              v-model="deviceHistoryStatus.handleStatus" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <span>处理过程:</span><br />
            <el-input type="textarea" :rows="2" placeholder="请输入处理说明" v-model="deviceHistoryStatus.process"
              style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item>
            <span>选择类型：</span>
            <el-radio-group v-model="processType">
              <el-radio :label="1">真实</el-radio>
              <el-radio :label="2">误报</el-radio>
              <el-radio :label="3">检修</el-radio>
              <el-radio :label="4">测试</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="clearProcessType">取 消</el-button>
          <el-button type="primary" @click="updateHandleStatus">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 一键处理框 -->
    <div>
      <el-dialog title="处理异常设备" :visible.sync="visible" width="30%" style="background:rgba(0,0,0,0.5)">
        <div>
          <div style="width:100%">
            <span style="font-size:16px">选择处理类型：</span>
            <el-radio-group v-model="processType">
              <el-radio :label="1" style="margin:10px">真实</el-radio>
              <el-radio :label="2" style="margin:10px">误报</el-radio>
              <el-radio :label="3" style="margin:10px">检修</el-radio>
              <el-radio :label="4" style="margin:10px">测试</el-radio>
            </el-radio-group>
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="deviceHistoryStatus.process">
            </el-input>
          </div>
        </div>
        <div slot="footer">
          <el-button @click="visible = false">取 消</el-button>
          <el-button type="primary" @click="updateHandleStatus">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 查看框 -->
    <div v-if="dialogTableVisibleValue">
      <el-dialog title="具体数据" :visible.sync="dialogTableVisibleValue">
        <el-table :data="deviceHistoryValues" :header-cell-style="{ textAlign: 'center', background: '#eef1f6' }"
          :cell-style="{ textAlign: 'center' }">
          <el-table-column property="deviceId" label="设备型号"></el-table-column>
          <el-table-column property="typeName" label="设备类型"></el-table-column>
          <el-table-column property="valueName" label="属性"></el-table-column>
          <el-table-column property="value" label="值"></el-table-column>
          <el-table-column property="createTime" label="创建时间"></el-table-column>
        </el-table>
        <div>
          <line-chart v-if="isShowTu" :dateList="dateList" :valueList="valueList" :hValue="hValue"
            :lValue="lValue"></line-chart>
        </div>
      </el-dialog>
    </div>
    <!-- 播放视频弹窗 -->
    <el-dialog title="视频" :visible.sync="playerDialogVisible" :destroy-on-close=true width="1280px" top="7vh"
      style="background:rgba(0,0,0,0.5)">
      <div class="videoClass" style="height:75.5vh;">
        <cus-player ref="video0"></cus-player>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import companySystem from "@/api/managementApi/CompanyInfo";
import BaseData from "@/assets/config/BaseData.js";
import { export_json_to_excel } from "../../introduce/Export2Excel";

import DeviceHistoryStatus from "../../api/manageApi/DeviceHistoryStatus";
import DeviceHistoryValue from "../../api/manageApi/DeviceHistoryValue";
import DeviceTypeManagement from "../../api/manageApi/DeviceTypeManagement";
import crtDeviceCoordinate from "../../api/manageApi/CrtDeviceCoordinate";
import lineChart from "@/components/echarts/lineChart";
import deviceInfo from "../../api/manageApi/DeviceInfoManagement";    //包含查询绑定视频的方法
import CusPlayer from '../videos/CusPlayer.vue'  //视频组件

// vuex
import { mapState} from 'vuex'

// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
import DeviceInfoManagement from "../../api/manageApi/DeviceInfoManagement";

export default {
  components: {
    lineChart,
    CusPlayer
  },
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '设备号',
      }, {
        id: 1,
        title: '设备类型',
      }, {
        id: 2,
        title: '公司名称',
      }, {
        id: 3,
        title: '安装位置',
      }, {
        id: 4,
        title: '报警类型',
      }, {
        id: 5,
        title: '报警时间',
      }, {
        id: 6,
        title: '处理状态',
      }, {
        id: 7,
        title: '处理过程',
      }, {
        id: 8,
        title: '处理结果',
      },{
        id: 9,
        title: '处理说明',
      }, {
        id: 10,
        title: '处理类型',
      }],
      tableTitle: ['设备号', '设备类型', '公司名称', '安装位置', '报警类型', '报警时间', '处理状态', '处理过程', '处理结果'],

      exportText: '导出报警历史',
      loading: false,
      loadingText: '正在导出',
      actualUrl: "",
      baseUrl: BaseData.baseUrl,

      dialogc: false,
      getCompanyNameById: {},
      companyNameById: {},
      companyInfoList: [], // 公司信息

      deviceHistoryStatuss1: [],
      deviceHistoryStatuss: [], // 状态历史表
      defaultProps: {
        // multiple: true,  多选框
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "id",
        checkStrictly: true,
        expandTrigger: 'hover',
        multiple: true
      },
      deviceHistoryStatus: {
        deviceId: "",
        typeName: "",
        valueName: "",
        createTime: "",
        handleStatus: "",
        process: "",
        processType: "",
      },
      userRightList: [],
      companyIds: '',

      deviceHistoryValues: [{}], //实时值历史表
      options: [
        // 查询处理状态下拉框数据
        {
          value: "已处理",
          label: "已处理",
        },
        {
          value: "未处理",
          label: "未处理",
        },
      ],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateValue: [],//时间选择
      companyName: '',//公司名称
      deviceType: [], // 查询设备类型下拉框数据
      companyNameList: [],// 查询公司名称下拉框数据
      handleStatus: "", //处理状态
      typeName: "", //设备类型
      deviceId: "", //设备号
      valueName: "", //设备属性
      value: "",//值
      process: "",//处理过程的说明
      processType: "",//处理类型的结果
      total: 0, //总页数
      currentPage: 1, //第几页
      dialogFormVisible: false, //是否显示处理弹框
      dialogTableVisibleValue: false, //是否显示查看框
      processType: 0, //处理状态（1为真实  2为误报  3为检修  4为测试）

      dateList: [], //折线图时间显示数组
      valueList: [], //折线图值显示数组
      hValue: "", //折线图高阈值显示
      lValue: "", //折线图低阈值显示
      isShowTu: false, //是否显示折线图

      playerDialogVisible: false, //视频播放器标志位,是否展示视频弹窗
      playUrl:'', //播放地址

      symbol: '',
      idStr: '',
      deviceIdStr: '',
      allCompanyInfo: [],
      visible: false,
      isQuery: false
    };
  },
  created() {
    if (this.$route.query.deviceId != null || this.$route.query.deviceId != undefined) {
      this.deviceId = this.$route.query.deviceId
      this.getAllByHandleStatus()
      console.log(this.deviceId);
    }
    this.getUsers()
    this.init();
    this.getAllDeviceInfo();

    this.getAllcompanyNameList();
  },
  computed: {
    ...mapState({ 'allCompanyList': state => state.companyInfo.allCompanyList })
  },
  mounted() {
    this.$emit("routerIsHistoryFunction", true);
  },
  destroyed() {
    var that = this;
    that.$emit("routerIsHistoryFunction", false);
  },
  methods: {
    // 根据公司名称查询 
    searchDeviceCompanyInfo(val) {
      // console.log(val);
      
      let companyNode = this.$refs.cascader1.getCheckedNodes()
      console.log(companyNode);
      let arr = []
      for(let i = 0; i< companyNode.length; i++){
        arr.push(companyNode[i].value)
      }
      console.log(arr);
      this.companyIds = arr.toString()
      console.log(this.companyIds);
    },
    //复选框点击事件
    handleSelectionChange(value) {
      this.dealWith = value
      console.log(this.dealWith);
    },
    //重置按钮的单击事件方法
    async restList() {
      //点击了重置按钮之后，将查询的条件进行只看
      this.handleStatus = "";
      this.typeName = "";
      this.deviceId = "";
      this.value = "";
      this.companyName = "";
      this.endTime = "";
      this.dateValue = "";
      this.getUsers()
      this.init();
      this.getAllDeviceInfo();
    },

    init() {
      this.getCompanyInfo();
    },
    handle() {
      this.dialogc = true;
    },

    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备号')
      } else {
        this.tableTitle = value
      }
    },

    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "history") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      console.log(this.userRightList);
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },

    // 一键处理
    aKeyProcess() {
      this.symbol = '一键处理'
      if (this.dealWith.length < 1) {
        this.$message.warning('请选择处理设备')
      } else {
        this.visible = true
        this.idStr = ''
        this.deviceIdStr = ''
        let arrId = []
        let arrDeviceId = []
        for (let i = 0; i < this.dealWith.length; i++) {
          arrId.push(this.dealWith[i].id)
        }
        for (let i = 0; i < this.dealWith.length; i++) {
          arrDeviceId.push(this.dealWith[i].deviceId)
        }
        this.idStr = arrId.toString()//id
        this.deviceIdStr = arrDeviceId.toString()//deviceId
        console.log(this.idStr);
      }
    },
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        console.log(res);
        this.allCompanyInfo = res.data.data.list
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
        this.getAll();
      });
    },
    goSelect() {
      this.$router.push("/Select");
    },

    tableRowClassName({ row, rowIndex }) {
      // 报警为红色，故障为黄色

      if (row.value.indexOf("警") !== -1 && row.handleStatus === "未处理") {
        return "warning-row";
      } else if (
        (row.value.indexOf("失联") !== -1 ||
          row.value.indexOf("故障") !== -1) &&
        row.handleStatus === "未处理"
      ) {
        return "success-row";
      } else {
        return "";
      }
    },
    //查询方法
    getAll() {
      let obj = {}
      obj.currentPage = this.currentPage
      obj.handleStatus = this.handleStatus
      obj.typeName = this.typeName
      obj.deviceId = this.deviceId
      obj.valueName = this.valueName
      obj.value = this.value
      obj.companyName = this.companyIds;
      obj.process = this.process;
      obj.processType = this.processType
      // obj.companyName = "test11";
      // obj.companyName = this.companyName;
      if (this.dateValue != null) {
        obj.startTime = this.dateValue[0]
        obj.endTime = this.dateValue[1]
      }

      
      //获取所有历史表中的数据(分页)
      console.log("传递的数据", obj);
      DeviceHistoryStatus.getAll(obj).then((resp) => {
        if (resp) {
          this.deviceHistoryStatuss = resp.data.data.list;
          console.log(this.deviceHistoryStatuss);
          this.total = resp.data.data.total;
          for (let i = 0; i < this.deviceHistoryStatuss.length; i++) {
            this.deviceHistoryStatuss[i].companyName = this.companyNameById[this.deviceHistoryStatuss[i].companyId];
            if (this.deviceHistoryStatuss[i].note == "null") {
              this.deviceHistoryStatuss[i].note = "";
            }
          }
        }
      });
    },

    //获取所有设备类型
    getAllDeviceInfo() {
      DeviceTypeManagement.getAllDeviceType().then((resp) => {
        if (resp) {
          this.deviceType = resp.data.data.list;
        }
      });
    },

    //获取所有公司名称
    getAllcompanyNameList() {
      console.log("11111"+ this.companyName);
      companySystem.getCompanyInfo(this.companyName).then((res) => {
        this.companyNameList = res.data.data.list;
        console.log("后端接口中拿到的所有公司名称,",this.companyNameList);
      })
    },
    toCRT(row) {
      crtDeviceCoordinate.getCoordinateByDeviceId(row.deviceId).then((res) => {
        console.log(res);
        if (res.data.message == '失败') {
          this.$message({
            type: "warning",
            message: "该设备尚未添加点位！",
          });
        } else {
          this.$router.push({
            name: "CRTShow",
            params: {
              CRTId: res.data.data.data.crtPictureId,
              deviceId: res.data.data.data.deviceInfoId,
            },
          });

        }
      });
    },
    //视频
    async showVideo(row) {
      const res = await DeviceInfoManagement.getPlayUrlByDeviceId(row.deviceId)
      if (res.data.data.playUrl) {
        this.playUrl = res.data.data.playUrl;
        //当前页弹窗播放
        if (this.playUrl != "") {
          this.playerDialogVisible = true;
          this.$nextTick(() => {
            console.log(this.$refs["video0"]);
            this.$refs["video0"].createPlayer(this.playUrl, false, "0");
          })
        } else {
          this.$message({
            type: "error",
            message: "获取rtsp地址失败!",
          });
        }
      }
      else if (row.videoUrl) {
        window.location.href = row.videoUrl;
      } else {
        //提示信息
        this.$message({
          type: "warning",
          message: "该设备未绑定视频设备！",
        });
      }
    },
    indexMethod(index) {
      // 序号递增
      const currentPage = this.currentPage; // 当前页码
      const pageSize = 10; // 每页条数
      return index + 1 + (currentPage - 1) * pageSize; // 返回表格序号
    },
    handleCurrentChange(val) {
      // 第几页
      this.currentPage = val;
      this.init();
    },
    handleClick(row) {
      this.symbol = '处理'
      //处理
      this.dialogFormVisible = true;
      this.deviceHistoryStatus = row;
      
    },
    //处理修改状态增加处理过程和类型
    updateHandleStatus() {
      if (this.symbol == '处理') {
        console.log(this.processType)
        if (this.processType === 1) {
          this.deviceHistoryStatus.processType = "真实";
        } else if (this.processType === 2) {
          this.deviceHistoryStatus.processType = "误报";
        } else if (this.processType === 3) {
          this.deviceHistoryStatus.processType = "检修";
        } else if (this.processType === 4) {
          this.deviceHistoryStatus.processType = "测试";
        } else {
          this.dialogFormVisible = true;
          this.$message.error("您没有选择处理类型！");
        }
        if (
          this.deviceHistoryStatus.processType !== "" &&
          this.deviceHistoryStatus.processType != null
        ) {
          let obj = {}
          obj.deviceId = this.deviceHistoryStatus.deviceId
          obj.process = this.deviceHistoryStatus.process
          obj.processType = this.deviceHistoryStatus.processType
          obj.id = this.deviceHistoryStatus.id
          DeviceHistoryStatus.updateRecord(obj).then(res => {
            if (res.data.message == "处理成功") {
              this.$message({
                message: "处理成功！",
                type: "success",
              });
            } else {
              this.$message({
                message: "处理失败！",
                type: "error",
              });
            }
            this.init()
          })
        }
        this.processType = 0;
        this.deviceHistoryStatus.processType = "";
        this.dialogFormVisible = false;
      } else {
        if (this.symbol == '一键处理') {
          this.dialogFormVisible = false;
          console.log(this.processType)
          if (this.processType === 1) {
            this.deviceHistoryStatus.processType = "真实";
          } else if (this.processType === 2) {
            this.deviceHistoryStatus.processType = "误报";
          } else if (this.processType === 3) {
            this.deviceHistoryStatus.processType = "检修";
          } else if (this.processType === 4) {
            this.deviceHistoryStatus.processType = "测试";
          } else {
            this.dialogFormVisible = true;
            this.$message.error("您没有选择处理类型！");
          }
          if (
            this.deviceHistoryStatus.processType !== "" &&
            this.deviceHistoryStatus.processType != null
          ) {
            let obj = {}
            obj.deviceId = this.deviceIdStr
            obj.process = this.deviceHistoryStatus.process
            obj.processType = this.deviceHistoryStatus.processType
            obj.id = this.idStr
            DeviceHistoryStatus.updateRecord(obj).then(res => {
              if (res.data.message == "处理成功") {
                this.$message({
                  message: "处理成功！",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "处理失败！",
                  type: "error",
                });
              }
              this.init()
            })
          }
          this.processType = 0;
          this.deviceHistoryStatus.processType = "";
          this.visible = false
        }
      }
    },
    clearProcessType() {
      //处理框点击取消清空数据
      this.dialogFormVisible = false;
      this.processType = 0;
      this.deviceHistoryStatus.process = "";
    },
    getAllByHandleStatus() {
      //条件查询
      this.init();
      this.isQuery = true
    },
    getOneDeviceHistoryValue(row) {
      //显示报警或故障时的具体数据

      if (row.typeName.indexOf("摄像头") != -1) {
        this.dialogc = true;
        this.actualUrl = row.notifySystemId;
      } else {
        this.isShowTu = false;
        DeviceHistoryValue.getOneByDeviceHistoryStatusId(row.id).then(
          (resp) => {
            if (resp.data.data.one.length != 0) {
              this.deviceHistoryValues = resp.data.data.one;
              setTimeout(() => {
                this.dialogTableVisibleValue = true;
              }, 100);
            } else {
              this.$message({
                dangerouslyUseHTMLString: true,
                message: "<strong>该设备没有实时值</strong>",
              });
            }
          }
        );
        DeviceHistoryValue.getDayValue(
          row.deviceId,
          row.value,
          row.createTime,
          row.typeId
        ).then((res) => {
          console.log(res);
          if (res.data.message == "不显示") {
            this.isShowTu = false;
          } else {
            this.dateList = res.data.data.dateList;
            this.valueList = res.data.data.valueList;
            this.hValue = res.data.data.hValue;
            this.lValue = res.data.data.lValue;
            this.isShowTu = true;
          }
        });
      }
    },
    
    //导出excel
    export2Excel() {
      if(this.isQuery == true){
      this.loading = true
      let obj = {}
      obj.currentPage = this.currentPage
      obj.handleStatus = this.handleStatus
      obj.typeName = this.typeName
      obj.deviceId = this.deviceId
      obj.valueName = this.valueName
      obj.value = this.value
      obj.companyName = this.companyName;
      obj.process = this.process;
      obj.processType = this.processType
      // obj.companyName = "test11";
      // obj.companyName = this.companyName;
      if (this.dateValue != null) {
        obj.startTime = this.dateValue[0]
        obj.endTime = this.dateValue[1]
      }
      //获取所有历史表中的数据(分页)
      console.log("传递的数据",obj);
      DeviceHistoryStatus.getAllHistoryRecord(obj).then((resp) => {
        console.log(resp);
        if (resp) {
          this.deviceHistoryStatuss1 = resp.data.data.list;
          for(let i = 0; i < this.deviceHistoryStatuss1.length; i++){
            for(let j = 0; j < this.allCompanyInfo.length; j++){
              if(this.deviceHistoryStatuss1[i].companyId == this.allCompanyInfo[j].id){
                this.deviceHistoryStatuss1[i].companyId = this.allCompanyInfo[j].name
              }
            }
          }
          console.log(this.deviceHistoryStatuss1);
          require.ensure([], () => {
            const tHeader = [
              "所在公司",
              "设备位置",
              "设备类型",
              "设备状态",
              "处理状态",
              "处理说明",
			  "报警时间",
              
            ]; // 对应表格输出的中文title
            const filterVal = [
              "companyId",
              "note",
              "typeName",
              "value",
              "handleStatus",
              "process",
			  "createTime"
            ]; // 对应表格输出的数据
            const list = this.deviceHistoryStatuss1; // 表格data

            const data = this.formatJson(filterVal, list);

            export_json_to_excel(tHeader, data, "历史报警记录"); // 对应下载文件的名字
          });
          this.loading = false;
        }
      })
    }else{
      this.loading = true
      let obj = {}
      if (this.handleStatus != "") {
        obj.handleStatus = this.handleStatus
      }
      if (this.typeName != "") {
        obj.typeName = this.typeName
      }
      if (this.deviceId != "") {
        obj.deviceId = this.deviceId
      }
      if (this.value != "") {
        obj.value = this.value
      }
      if (this.dateValue != null && this.dateValue[0] != undefined && this.dateValue[1] != undefined) {
        obj.startTime = this.dateValue[0]
        obj.endTime = this.dateValue[1]
      }
      // 获取所有设备信息导出
      DeviceHistoryStatus.getAllHistory(obj).then((resp) => {
        if (resp) {
          this.deviceHistoryStatuss1 = resp.data.data.list;
          for(let i = 0; i < this.deviceHistoryStatuss1.length; i++){
            for(let j = 0; j < this.allCompanyInfo.length; j++){
              if(this.deviceHistoryStatuss1[i].companyId == this.allCompanyInfo[j].id){
                this.deviceHistoryStatuss1[i].companyId = this.allCompanyInfo[j].name
              }
            }
          }
          console.log(this.deviceHistoryStatuss1);
          require.ensure([], () => {
            const tHeader = [
              "所在公司",
              "设备位置",
              "设备类型",
              "设备状态",
              "处理状态",
              "处理说明",
			  "报警时间"
            ]; // 对应表格输出的中文title
            const filterVal = [
              "companyId",
              "note",
              "typeName",
              "value",
              "handleStatus",
              "process",
			  "createTime"
            ]; // 对应表格输出的数据
            const list = this.deviceHistoryStatuss1; // 表格data

            const data = this.formatJson(filterVal, list);

            export_json_to_excel(tHeader, data, "历史报警记录"); // 对应下载文件的名字
          });
          this.loading = false;
        }
      })
    }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
  }
</script>

<style lang="scss" >
#app {
}

.notify-warning {
  background: rgba(177, 58, 60, 0.979) !important;
  box-shadow: 3px 3px 2px rgb(235, 106, 106) !important;
}

.el-icon-warning {
  color: #fe2e2e !important;
}

.notify-fault {
  background: rgba(238, 217, 33, 0.5) !important;
  box-shadow: 3px 3px 2px rgb(208, 238, 138) !important;
}

.el-icon-info {
  color: #f3f781 !important;
}

.el-table .warning-row {
  background: #f78181;
}

.el-table .success-row {
  background: #faac58;
}

.el-pagination {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 5px;
}

.block {
  background: #fff;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.inPutStyle {
  width: 180px;
}
.item {
  margin: 0 5px;
}</style>

